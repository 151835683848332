import React from 'react'
import { Link } from 'gatsby'
import footerLogo from '../images/at-logo-footer.png'

const copyrightYear = new Date().getFullYear()

function Footer() {
  return (
    <footer>
      <div className="footer__content">
        <div className="footer__content__left">
          <div className="links company">
            <header>Company</header>
            <Link to="/press">Press</Link>
            <Link to="/careers">Careers</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
            <Link to="/corporate-contact">Corporate Contact</Link>
            <Link to="/site-map">Site Map</Link>
          </div>
          <div className="links social">
            <header>Social</header>
            <a href="https://www.linkedin.com/company/adheretech" target="_blank" rel="noopener noreferrer">
              LinkedIn
            </a>
            <a href="https://www.facebook.com/AdhereTech" target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
            <a href="https://twitter.com/AdhereTech" target="_blank" rel="noopener noreferrer">
              Twitter
            </a>
          </div>
        </div>
        <div className="footer__content__right">
          <div className="image">
            <img src={footerLogo} alt="adheretech logo" width={281} />
          </div>
          <div className="copyright">
            <p>© {copyrightYear} AdhereTech. All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
