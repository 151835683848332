import React from 'react'

const copyrightYear = new Date().getFullYear()

const LandingPageFooter = () => {
  return (
    <footer className="landing-page-footer">
      <div>
        <div>
          <a href="https://adheretech.com/privacy-policy" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </div>
        <div>Copyright © {copyrightYear} AdhereTech.</div>
        <div>All Rights Reserved.</div>
      </div>
    </footer>
  )
}

export default LandingPageFooter
