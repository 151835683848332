// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-biopharma-js": () => import("./../../../src/pages/biopharma.js" /* webpackChunkName: "component---src-pages-biopharma-js" */),
  "component---src-pages-blog-adherence-crisis-js": () => import("./../../../src/pages/blog/adherence-crisis.js" /* webpackChunkName: "component---src-pages-blog-adherence-crisis-js" */),
  "component---src-pages-blog-demographic-perspective-js": () => import("./../../../src/pages/blog/demographic-perspective.js" /* webpackChunkName: "component---src-pages-blog-demographic-perspective-js" */),
  "component---src-pages-blog-healthier-planet-js": () => import("./../../../src/pages/blog/healthier-planet.js" /* webpackChunkName: "component---src-pages-blog-healthier-planet-js" */),
  "component---src-pages-blog-impact-cognitive-processes-js": () => import("./../../../src/pages/blog/impact-cognitive-processes.js" /* webpackChunkName: "component---src-pages-blog-impact-cognitive-processes-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-more-than-medicine-js": () => import("./../../../src/pages/blog/more-than-medicine.js" /* webpackChunkName: "component---src-pages-blog-more-than-medicine-js" */),
  "component---src-pages-blog-silent-epidemic-js": () => import("./../../../src/pages/blog/silent-epidemic.js" /* webpackChunkName: "component---src-pages-blog-silent-epidemic-js" */),
  "component---src-pages-blog-understanding-medication-management-js": () => import("./../../../src/pages/blog/understanding-medication-management.js" /* webpackChunkName: "component---src-pages-blog-understanding-medication-management-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-charging-aidia-bottle-js": () => import("./../../../src/pages/charging-aidia-bottle.js" /* webpackChunkName: "component---src-pages-charging-aidia-bottle-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-corporate-contact-js": () => import("./../../../src/pages/corporate-contact.js" /* webpackChunkName: "component---src-pages-corporate-contact-js" */),
  "component---src-pages-evidence-js": () => import("./../../../src/pages/evidence.js" /* webpackChunkName: "component---src-pages-evidence-js" */),
  "component---src-pages-faqs-bottle-js": () => import("./../../../src/pages/faqs-bottle.js" /* webpackChunkName: "component---src-pages-faqs-bottle-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-getting-started-aidia-bottle-js": () => import("./../../../src/pages/getting-started-aidia-bottle.js" /* webpackChunkName: "component---src-pages-getting-started-aidia-bottle-js" */),
  "component---src-pages-healthplans-and-payers-js": () => import("./../../../src/pages/healthplans-and-payers.js" /* webpackChunkName: "component---src-pages-healthplans-and-payers-js" */),
  "component---src-pages-how-aidia-works-js": () => import("./../../../src/pages/how-aidia-works.js" /* webpackChunkName: "component---src-pages-how-aidia-works-js" */),
  "component---src-pages-how-to-get-aidia-js": () => import("./../../../src/pages/how-to-get-aidia.js" /* webpackChunkName: "component---src-pages-how-to-get-aidia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patient-support-js": () => import("./../../../src/pages/patient-support.js" /* webpackChunkName: "component---src-pages-patient-support-js" */),
  "component---src-pages-pharmacies-and-healthsystems-js": () => import("./../../../src/pages/pharmacies-and-healthsystems.js" /* webpackChunkName: "component---src-pages-pharmacies-and-healthsystems-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-researchers-js": () => import("./../../../src/pages/researchers.js" /* webpackChunkName: "component---src-pages-researchers-js" */),
  "component---src-pages-site-map-js": () => import("./../../../src/pages/site-map.js" /* webpackChunkName: "component---src-pages-site-map-js" */),
  "component---src-pages-support-services-js": () => import("./../../../src/pages/support-services.js" /* webpackChunkName: "component---src-pages-support-services-js" */),
  "component---src-pages-the-adherence-problem-js": () => import("./../../../src/pages/the-adherence-problem.js" /* webpackChunkName: "component---src-pages-the-adherence-problem-js" */),
  "component---src-pages-welcome-aidia-system-bottle-js": () => import("./../../../src/pages/welcome-aidia-system-bottle.js" /* webpackChunkName: "component---src-pages-welcome-aidia-system-bottle-js" */)
}

