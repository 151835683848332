import React from 'react'
import Layout from './src/components/Layout'
import SEO from './src/components/SEO'

export function wrapPageElement({ element, props }) {
  return (
    <>
      <SEO />
      <Layout {...props}>{element}</Layout>
    </>
  )
}