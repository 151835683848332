import React, { useState } from 'react'
import { Link } from 'gatsby'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { getLinkToExternalApp } from '../utils/api'
import { FaChevronCircleRight } from 'react-icons/fa'
import adheretechLogo from '../images/adheretech_logo.svg'

const navItems = [
  {
    page: `The Adherence Problem`,
    url: `/the-adherence-problem`,
  },
  {
    category: `What Is the\nAidia System`,
    children: [
      { page: `How Aidia Works`, url: `/how-aidia-works` },
      {
        page: `How To Get Aidia`,
        url: `/how-to-get-aidia`,
      },
      {
        page: `Support Services`,
        url: `/support-services`,
      },
      {
        page: `FAQs`,
        url: `/faqs`,
      },
    ],
  },
  {
    category: `Why Partner\nWith Us`,
    children: [
      {
        page: `BioPharma`,
        url: `/biopharma`,
      },
      {
        page: `Pharmacies & Health Systems`,
        url: `/pharmacies-and-healthsystems`,
      },
      {
        page: `Health Plans & Payers`,
        url: `/healthplans-and-payers`,
      },
      {
        page: `Researchers`,
        url: `/researchers`,
      },
    ],
  },
  {
    page: `Evidence`,
    url: `/evidence`,
  },
  {
    page: `About Us`,
    url: `/about-us`,
  },
  {
    page: 'Blog',
    url: '/blog',
  },
  {
    page: `Contact Us`,
    url: `/contact-us`,
  },
]

export default function Navigation(props) {
  const { type } = props

  const pathname = window.location.pathname.replace(/\//g, '')
  const url = `/${pathname}`

  let activeCategory = null

  for (let navItem of navItems) {
    if (navItem.category) {
      for (let child of navItem.children) {
        if (child.url === url) {
          activeCategory = navItem.category
          break
        }
      }
    } else {
      if (navItem.url === url) {
        activeCategory = navItem.page
        break
      }
    }
  }

  const [navExpanded, setNavExpanded] = useState(false)

  const renderNavItems = () => {
    return navItems.map((navItem) => {
      if (navItem.category) {
        return (
          <NavDropdown
            renderMenuOnMount={true}
            id="nav-dropdown"
            title={navItem.category}
            key={navItem.category}
            className={`${activeCategory === navItem.category ? 'active' : ''}`}
          >
            {navItem.children.map((child) => {
              return (
                <NavDropdown.Item as="li" key={child.page}>
                  <Link to={child.url} activeClassName="active" onClick={() => setNavExpanded(false)}>
                    <span>{child.page}</span>
                  </Link>
                </NavDropdown.Item>
              )
            })}
          </NavDropdown>
        )
      } else {
        return (
          <Nav.Link as="li" key={navItem.page}>
            <Link to={navItem.url} activeClassName="active" onClick={() => setNavExpanded(false)}>
              <span>{navItem.page}</span>
            </Link>
          </Nav.Link>
        )
      }
    })
  }

  return (
    <div className={`navigation ${type}`}>
      <div className="navigation__collapse__divider"></div>
      <div className="navigation__top">
        <ul className="navigation__top__content">
          <Nav.Link as="li">
            <Link to="/patient-support">
              Patient Support <FaChevronCircleRight className="chevron-circle-right" />
            </Link>
          </Nav.Link>
          <Nav.Link as="li">
            <a href={getLinkToExternalApp('app')} target="_blank" rel="noopener noreferrer">
              Partner Login <FaChevronCircleRight className="chevron-circle-right" />
            </a>
          </Nav.Link>
        </ul>
      </div>
      <div className="navigation__main">
        <div className="navigation__main__content">
          <Navbar expand="lg" onToggle={(e) => setNavExpanded(e)} expanded={navExpanded}>
            <div className="navigation__main__content__left">
              <Navbar.Brand>
                <Link to="/">
                  <img src={adheretechLogo} alt="adheretech logo" width={241} height={28} />
                </Link>
              </Navbar.Brand>
            </div>
            <div className="navigation__main__content__left--small">
              <Navbar.Brand>
                <Link to="/">
                  <img src={adheretechLogo} alt="adheretech logo" width={172} height={20} />
                </Link>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle aria-controls="navigation" />
            <div className="navigation__main__content__right">
              <Navbar.Collapse>
                <Nav as="ul">
                  <Nav.Link className="home" as="li" onClick={() => setNavExpanded(false)}>
                    <Link to="/">Home</Link>
                  </Nav.Link>
                  {renderNavItems()}
                  <div className="collapse__bottom">
                    <Nav.Link as="li">
                      <Link to="/patient-support" onClick={() => setNavExpanded(false)}>
                        Patient Support <FaChevronCircleRight className="chevron-circle-right" />
                      </Link>
                    </Nav.Link>
                    <Nav.Link as="li">
                      <a href={getLinkToExternalApp('app')} target="_blank" rel="noopener noreferrer">
                        Partner Login <FaChevronCircleRight className="chevron-circle-right" />
                      </a>
                    </Nav.Link>
                  </div>
                </Nav>
              </Navbar.Collapse>
            </div>
          </Navbar>
        </div>
      </div>
    </div>
  )
}
