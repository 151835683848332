import React from 'react'
import Navigation from './Navigation'
import Footer from './Footer'
import LandingPageFooter from './LandingPageFooter'
import '../styles/styles.scss'

// Onboarding pages originally from hubspot, considered separate from the
// marketing site so they should not have the usual header/footer
const landingPages = [
  '/welcome-aidia-system-bottle',
  '/getting-started-aidia-bottle',
  '/charging-aidia-bottle',
  '/faqs-bottle',
]

export default function Layout({ children }) {
  if (landingPages.some((path) => children.props.location.pathname.includes(path))) {
    return (
      <div>
        {children}
        <LandingPageFooter />
      </div>
    )
  }

  return (
    <div>
      <Navigation type="secondary" />
      <Navigation type="primary" />
      <div className="content">{children}</div>
      <Footer />
    </div>
  )
}
