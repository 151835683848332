import axios from 'axios'
import Cookies from 'js-cookie'

const isBrowser = typeof window !== 'undefined'

export const makeRequest = (url = '/', method = 'GET', data = {}, params = {}, headerAdditions = {}) => {
  const baseURL = `https://${process.env.GATSBY_REACT_APP_API_HOST}.adheretech.com`

  const headers = {
    ...headerAdditions,
    'Content-Type': 'application/json'
  }

  const request = axios({
    baseURL,
    timeout: 20000,
    url,
    method,
    data,
    params,
    headers,
  })

  return request
}

export const getLinkToExternalApp = (appName) => {
  const env = process.env.GATSBY_REACT_APP_API_HOST.replace('api-v2', '')
  return `https://${appName}${env}.adheretech.com`
}

export const get = (path, params = {}, headers = {}) => {
  return makeRequest(path, 'GET', null, params, headers)
}

export const post = (path, data = {}, headers = {}) => {
  return makeRequest(path, 'POST', data, null, headers)
}

export const mockRequest = (value) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(value)
    }, 500)
  })

export const postPartnership = (data = {}) => {
  const headers = {
    formId: process.env.GATSBY_HUBSPOT_PARTNERSHIP_FORM_ID,
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
  }

  const hutk = isBrowser ? Cookies.get('hubspotutk') : null
  if (hutk) {
    headers.hutk = hutk
  }

  return post('/forms/partnership/submissions', data, headers)
}

export const postPatientSupport = (data = {}) => {
  const headers = {
    formId: process.env.GATSBY_HUBSPOT_PATIENT_SUPPORT_FORM_ID,
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
  }

  const hutk = isBrowser ? Cookies.get('hubspotutk') : null
  if (hutk) {
    headers.hutk = hutk
  }

  return post('/forms/patient-support/submissions', data, headers)
}

export const postProductEligibility = (data = {}) => {
  const headers = {
    formId: process.env.GATSBY_HUBSPOT_PRODUCT_ELIGIBILITY_FORM_ID,
    portalId: process.env.GATSBY_HUBSPOT_PORTAL_ID,
  }

  const hutk = isBrowser ? Cookies.get('hubspotutk') : null
  if (hutk) {
    headers.hutk = hutk
  }

  return post('/forms/eligibility/submissions', data, headers)
}
